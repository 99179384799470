
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default Vue.extend({
  name: "MenteeWalletHeader",
  components: {
    DatePicker
  },
  props: {
    loading: Boolean,
    propDate: String,
    total_balance: String,
    pending_balance: String,
    json_data: Array,
    json_excel: Array
  },
  data() {
    return {
      date: this.propDate
    };
  },
  methods: {
    nextOrPrevMonth(type: string) {
      const amount = type === "next" ? 1 : -1;
      this.date = moment(this.date, "MMMM, YYYY")
        .add(amount, "month")
        .format("MMMM, YYYY")
        .toString();
      this.$emit("updateDate", this.date);
    },
    dateChange() {
      this.$emit("updateDate", this.date);
    }
  }
});
